//import { useQuery } from "@apollo/react-hooks";
import VideoJS from './components/Video' 
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal"; //for fade

import { Body, ConnectButton, ButtonContainer, HeaderOne, 
        SectionOne, SectionTwo, SectionOneContent, SectionOneContentTwo, SectionTwoContent, SectionTwoContentTwo,
        SectionTwoContentTwoText, HandImage, FlatHandImage, SectionFive, SectionFiveHeaderOne, SectionFiveContent,
        ActionImage, ActionText, SectionThree, SectionFour, SectionFourContent, SectionFourHeaderOne, SectionFourContentText,
        SectionFiveContentTwoText, SectionFiveHeaderTwo, SectionSix, DownArrowImage, Roadmap, Contact, RoadmapHeaderOne, 
        SectionFiveContentTwo, SectionFiveContentText, ContactHeaderOne, ContactContent, LogoImage, SectionFourContentTwoText,
        SectionSixContent, RoadmapTable, RoadmapTableRow, RoadmapTableLeftColumn, RoadmapTableRightColumn, SectionFourContentTwo,
        RoadmapTableColumnCenter, RoadmapDot, RoadmapTableLeftHeader, RoadmapTableRightHeader, RoadmapTableTopRow,
        RoadmapTableBottomRow, RoadmapTableHeader, RoadmapText, SabetHand} from "./components";
import Navbar from './components/Nav/Navbar';
import arrow from "./img/arrow.svg";
import victoryHand from "./img/hand.png"; 
import flatHand from "./img/hand2.png";
import twitterLogo from "./img/twitter-64.png";
import openseaLogo from "./img/opensea.png";
import instagramLogo from './img/instagram.png';
import actionImage from './img/right_arrow.png';
import sabetHand from './img/sabet.png';

import useWeb3Modal from "./hooks/useWeb3Modal";
//import GET_TRANSFERS from "./graphql/subgraph";

function App() {
//  const { loading, error, data } = useQuery(GET_TRANSFERS);
  const [account, setAccount] = useState("");
  const [rendered, setRendered] = useState("Connect");
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  const [connectError, setConnectError] = useState("");

  const playerRef = React.useRef(null);

  const videoJsOptions = { // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        //src: "https://www.w3schools.com/html/mov_bbb.mp4",
        //src: "https://kokako.me/mov_bbb.mp4",
        src: "https://kokako.me/webhand2.webm",
        //src: "https://paperhands-production.vercel.app/video/hand.mp4",
        type: "video/webm"
      }    
  //  {
      //src: "https://www.w3schools.com/html/mov_bbb.mp4",
  //    src: "https://kokako.me/webhand2.webm",
      //src: "https://paperhands-production.vercel.app/video/hand.mp4",
  //    type: "video/webm"
  //  }
   ]
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  //React.useEffect(() => {
  //  if (!loading && !error && data && data.transfers) {
  //    console.log({ transfers: data.transfers });
  //  }
  //}, [loading, error, data]);

  function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {  
    useEffect(() => {
      async function fetchAccount() {
        try {
          if (!provider) {
            return;
          }
          // Load the user's accounts.
          const accounts = await provider.listAccounts();
          setAccount(accounts[0]);  
          try {
              // Resolve the ENS name for the first account.
              const name = await provider.lookupAddress(accounts[0]);
              // Render either the ENS name or the shortened account address.
              if (name) {
                //setRendered(name);
                setRendered(account.substring(0, 4) + ".." + account.substring(40));
              } else {
                setRendered(account.substring(0, 6) + "..." );
                //setRendered(account.substring(0, 6) + "..." + account.substring(36));
              }
          } catch (err) {
            setRendered("Error");
            setConnectError("error");
            console.error(err);
          }
        } catch (err) {
          setAccount("");
          setRendered("Error");
          setConnectError("error");
          console.error(err);
        }
      }
      fetchAccount();
    });
  
    return (
      <ConnectButton
      onClick={() => {
        if (!provider) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
        }
      }}
    >
      {rendered !== "" && rendered}  
    </ConnectButton>
     );
  }

    return (
    <div>
      <Navbar />
      <Body>
        <SectionOne id = "home">
          <SectionOneContent>
              Paper<span style={{ color: "#cbfb45" }}>Ark</span> is building at the intersection of physical collectibles and digital art.
              <br /><br />
              <a href="#story" className='page-scroll'>
                  <DownArrowImage src={arrow} alt="DownArrow" />
              </a>
          </SectionOneContent>
          <SectionOneContentTwo>
          <ButtonContainer>
             <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
             {connectError ? "There was an error, please re-connect wallet" : "Hand of Artist"}
          </ButtonContainer>
            <HandImage src={victoryHand} alt="VictoryHand" />
          </SectionOneContentTwo>
        </SectionOne>
        <SectionTwo id = "story">
          <SectionTwoContent>
            <FlatHandImage src={flatHand} alt="FlatHand" />
          </SectionTwoContent>
          <SectionTwoContentTwo>
            <Fade>
            <HeaderOne>How We Got Here</HeaderOne>
            <SectionTwoContentTwoText>
              PaperArk is a specialized paper manufacturer located in the Northeast US. Our customizable paper is 300+ GSM, and similar to cold press. We offer opportunities for collaboration with artists and builders (physical and digital) working with blockchain technology. 
            </SectionTwoContentTwoText>
            <SectionTwoContentTwoText>
              In 2022 we are pleased to offer our own first generation NFTs. PaperArk community members will have access to a world of 3d paper-like assets first designed from an analog paper mache method. NFTs also include customizable, unlockable content for artists.
            </SectionTwoContentTwoText>
            <ActionText>
              View In Action 
              <a href="#art" className='page-scroll'>
                 <ActionImage src={actionImage} alt="Action Image" />
              </a>
            </ActionText>
            </Fade>
          </SectionTwoContentTwo>
        </SectionTwo>
        <SectionThree id = "art">
            <HeaderOne>ANALOG + PHYSICAL COLLECTIBLES + NFTS</HeaderOne>
            <br />
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        </SectionThree>
        <SectionFour id = "gallery">
          <Fade>
          <SectionFourContent>
            <SectionFourHeaderOne>FEATURED ARTIST | ALI SABET</SectionFourHeaderOne>
            <SectionFourContentText>
              Ali Sabet is an Iranian-American contemporary artist and designer. His work has been showcased around the world in collaboration with luxury brands such as Montblanc, Furla, BMW, and more.
            </SectionFourContentText>
          </SectionFourContent>
          <SectionFourContentTwo>
            <SectionFourContentTwoText>
              <SabetHand src={sabetHand} alt="SabetHand" />
            </SectionFourContentTwoText>
          </SectionFourContentTwo>
          </Fade>
        </SectionFour>
        <SectionFive id = "team">
          <SectionFiveContent>
            <SectionFiveHeaderOne>Team</SectionFiveHeaderOne>
            <SectionFiveContentText>
              Cut from the cloth of various namebrand NFTs, PaperArk consists of creative visionaires using pseudonymous profiles from the likes of BAYC, Mfers, and others. Expect to find us exploring, and operating our paper machines within those worlds and beyond.   
            </SectionFiveContentText>
          </SectionFiveContent>
          <SectionFiveContentTwo>
            <SectionFiveHeaderTwo>Kai Smith</SectionFiveHeaderTwo>
            <SectionFiveContentTwoText>
              Vision, paper making.
            </SectionFiveContentTwoText>
            <SectionFiveHeaderTwo>Sean Sullivan</SectionFiveHeaderTwo>
            <SectionFiveContentTwoText>
              Blockchain, wallets, payments.
            </SectionFiveContentTwoText>
            <SectionFiveHeaderTwo>Chuck Carter + Standard Magic</SectionFiveHeaderTwo>
            <SectionFiveContentTwoText>
              Vision, game strategy, paperworld architect; former lead artists: Myst, ZED.
            </SectionFiveContentTwoText>
            <SectionFiveHeaderTwo>Marcos Pelenur</SectionFiveHeaderTwo>
            <SectionFiveContentTwoText>
              Lead Developer, Blockchain, Web2/3
            </SectionFiveContentTwoText>
          </SectionFiveContentTwo>
        </SectionFive>
        <Fade>
        <Roadmap id = "roadmap">
            <RoadmapHeaderOne>Roadmap</RoadmapHeaderOne>
            <RoadmapTable>
              <RoadmapTableTopRow>
                <RoadmapTableLeftColumn>
                </RoadmapTableLeftColumn>
                 <RoadmapTableColumnCenter>
                 </RoadmapTableColumnCenter>
                <RoadmapTableRightColumn />
              </RoadmapTableTopRow>
              <RoadmapTableRow>
              <RoadmapTableLeftColumn>
              </RoadmapTableLeftColumn>
              <RoadmapTableColumnCenter>
                 <RoadmapDot>1</RoadmapDot>
              </RoadmapTableColumnCenter>
                <RoadmapTableRightColumn>
                   <RoadmapTableRightHeader>
                      Paper Hands
                   </RoadmapTableRightHeader>
                     <p>
                       PaperArk's debut NFTs. These hands are your token entry to the world of PaperArk.
                     </p>
                </RoadmapTableRightColumn>
              </RoadmapTableRow>
              <RoadmapTableRow>
              <RoadmapTableLeftColumn>
                 <RoadmapTableLeftHeader>
                     Artist Collaborations
                </RoadmapTableLeftHeader>
                 <p>
                   PaperArk NFTs include unlockable content including access to phsyical paper-based collectibles for use by artists and other builders. As a blank canvas for creatives, PaperArk will drive value across a wide variety of web3 communities.
                 </p>
              </RoadmapTableLeftColumn>
              <RoadmapTableColumnCenter>
                 <RoadmapDot>2</RoadmapDot>
              </RoadmapTableColumnCenter>
                <RoadmapTableRightColumn>
                 </RoadmapTableRightColumn>
              </RoadmapTableRow>
              <RoadmapTableRow>
              <RoadmapTableLeftColumn>
              </RoadmapTableLeftColumn>
              <RoadmapTableColumnCenter>
                 <RoadmapDot>3</RoadmapDot>
              </RoadmapTableColumnCenter>
                <RoadmapTableRightColumn>
                   <RoadmapTableRightHeader>
                      Conceptualizing a New World
                   </RoadmapTableRightHeader>
                     <p>
                       PaperArk will begin conceptualizing a world of paper. Team member, Chuck Carter (Myst, ZED) will lead this effort.
                     </p>
                </RoadmapTableRightColumn>
              </RoadmapTableRow>
              <RoadmapTableBottomRow>
              <RoadmapTableLeftColumn>
              </RoadmapTableLeftColumn>
              <RoadmapTableColumnCenter>
                 <RoadmapDot>4</RoadmapDot>
              </RoadmapTableColumnCenter>
                <RoadmapTableRightColumn>
                </RoadmapTableRightColumn>
              </RoadmapTableBottomRow>
            </RoadmapTable>
            <RoadmapTableHeader>End Result: Symbiotic Web 3.0</RoadmapTableHeader>
            <RoadmapText>Community driven and empowered with amazing art, PaperArk intends to be an enduring web3 operator.</RoadmapText>
        </Roadmap>
        </Fade>
        <Contact id = "contact">
          <ContactHeaderOne><a href="https://www.premint.xyz/paper-hands-by-paperark/" target="_blank" rel="noopener noreferrer">Join The Ark / Presale List</a></ContactHeaderOne>
          <ContactContent>
              <a href="https://twitter.com/paper__ark" target="_blank" rel="noopener noreferrer"><LogoImage src={twitterLogo} alt="Twitter Logo" /></a>
              <a href="https://www.instagram.com/paper.ark/" target="_blank" rel="noopener noreferrer"><LogoImage src={instagramLogo} alt="Instagram Logo" /></a>
              {/*<a href="#contact"><LogoImage src={discordLogo} alt="Discord Logo" /></a>*/}
              <a href="#contact"><LogoImage src={openseaLogo} alt="Opensea Logo" /></a>
          </ContactContent>
        </Contact>
        <SectionSix id = "footer">
          <SectionSixContent>
           <div className="logo">
              PAPER<span style={{ color: "#cbfb45" }}>ARK</span>
           </div>
          </SectionSixContent>
          <SectionSixContent>
          <p>
             Copyright 2022 All Rights Reserved Press | Terms of Use | Privacy Policy
          </p>
          </SectionSixContent>
        </SectionSix>
      </Body>
    </div>
  );
}

export default App;
