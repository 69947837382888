import React from 'react';
import styled from 'styled-components';
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });

const Ul = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    flex-flow: column nowrap;
    background-color: #000;
    z-index: -1;
    border-left 2px solid #cbfb45;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    @media (max-width: 768px) {
      width: 150px;
    }
    li {
      color: #fff;
      padding: 48px 2vw 0px 18px;
    }
    a:hover, a:visited, a:link, a:active
    {
      font-family: 'DM Serif Display', style;
      color: #fff;  
      text-decoration: none;
    }
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <li><a href='#home' className='page-scroll'>Home</a></li>
      <li><a href='#story' className='page-scroll'>Story</a></li>
      <li><a href='#art' className='page-scroll'>Art</a></li>
      <li><a href='#gallery' className='page-scroll'>Gallery</a></li>
      <li><a href='#team' className='page-scroll'>Team</a></li>
      <li><a href='#roadmap' className='page-scroll'>Roadmap</a></li>
      <li><a href='#contact' className='page-scroll'>Contact</a></li>
    </Ul>
  )
}

export default RightNav