import styled from "styled-components";
import backgroundImg from '../img/paper_background.png';
import backgroundImgTwo from '../img/paper_background2.png';
import mintButton from "../img/mint.png";

export const Body = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  min-height: calc(100vh - 70px);
`;

export const Header = styled.header`
  background-color: #000000;
  min-height: 70px;
  padding:0px 2vw 0px 2vw;
  margin:0px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
`;

export const HeaderRightContent = styled.header`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: 'DM Serif Display', style;
  font-size: calc(14px + 2vmin);
`;

export const SectionOne = styled.div`
  background-color: #000;
  width: 100%;
  margin:0px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
`;

export const SectionOneContent = styled.div`
  padding:10vh 5vw 0vh 3vw;
  margin:0px 0vw 0px 0px;
  align-items: center;
  color: white;
  width: 42vw;
  min-width: 150px;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: calc(25px + 2vmin);
  @media (max-width: 1400px) {
    padding:50px 0vw 15vh 3vw;
    font-size: calc(20px + 2vmin);
  }
  @media (max-width: 900px) {
    padding:50px 0vw 15vh 3vw;
    font-size: calc(10px + 2vmin);
  }
`;

export const SectionOneContentTwo = styled.div`
  display: flex;
  align-items: center;
  padding:0px 0px 0px 0px;
`;

export const HandImage = styled.img`
  width:80%;
  overflow: visible;
  pointer-events: auto;
  max-height: 80vh;
  max-width: 20vw;
  z-index:0;
  @media (max-width: 768px) {
    max-width: 30vw;
  }
`;

export const SectionTwo = styled.div`
  background: #000000;
  background-image: url(${backgroundImg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin:-80px 0px 0px 0px;
  padding:0px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: white;
`;

export const SectionThree = styled.div`
  align-items: center;
  display: flex;
  padding: 8vh 0px 6vh 0px;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60vw;
  z-index:0;
  p {
    font-size: calc(4px + 2vmin);
    font-family: 'Prompt', sans-serif;
    text-align: center;
    width: 70%;
  }
`;

export const RoadmapHeaderOne = styled.div`
  font-family: 'Bebas Neue', style;
  letter-spacing: 2px;
  font-size: calc(16px + 4vmin);
  margin-bottom: 60px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const RoadmapTable = styled.div`
  display: table;
  width: 45%; 
  @media (max-width: 1400px) {
    width: 80%
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const RoadmapTableRow = styled.div`
  display: table-row;
  margin: 0;
  padding: 0;
  height: 300px;
  @media (max-width: 768px) {
    height: 180px;
  }
`;

export const RoadmapTableTopRow = styled.div`
  display: table-row;
  margin: 0;
  padding: 0;
  height: 100px;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

export const RoadmapTableBottomRow = styled.div`
  display: table-row;
  margin: 0;
  padding: 0;
`;

export const RoadmapTableColumnCenter = styled.div`
  display: table-cell;
  margin: 0;
  padding: 0;
  width: 180px;
  background: linear-gradient(#cbfb45, #cbfb45) no-repeat center/3px 100%;
  text-align: center;
`;

export const RoadmapTableRightColumn = styled.div`
  display: table-cell;
  margin: 0;
  padding: 0 0px 0px 30px;
  width: 50%;
  p {
    font-size: calc(6px + 1vmin);
    line-height: calc(3px + 2vmin);
    font-family: 'Andale', sans-serif;
    text-align: left;
    font-weight: 100;
    @media (max-width: 1400px) {
      font-size: calc(10px + 1vmin);
      line-height: calc(6px + 2vmin);
    }
    @media (max-width: 900px) {
      font-size: calc(10px + 1vmin);
      line-height: calc(6px + 2vmin);
    }
  }
  @media (max-width: 768px) {
    padding: 0 5px 20px 0;
    width: 40%;
  }
`;

export const RoadmapTableLeftColumn = styled.div`
  display: table-cell;
  margin: 0;
  padding: 0 30px 0px 0;
  width: 50%;
  p {
    font-size: calc(6px + 1vmin);
    line-height: calc(3px + 2vmin);
    font-family: 'Andale', sans-serif;
    text-align: left;
    font-weight: 100;
    @media (max-width: 1400px) {
      font-size: calc(10px + 1vmin);
      line-height: calc(6px + 2vmin);
    }
    @media (max-width: 900px) {
      font-size: calc(10px + 1vmin);
      line-height: calc(6px + 2vmin);
    }
  }
  @media (max-width: 768px) {
    padding: 0 0 20px 5px;
    line-height: calc(10px + 2vmin);
    width: 40%;
  }
`;

export const RoadmapTableRightHeader = styled.div`
  font-size: calc(18px + 1vmin);
  font-family: 'Prompt', sans-serif;
  font-weight: 100; 
  color: #cbfb45;
  text-align: left;
  @media (max-width: 768px) {
    font-size: calc(12px + 1vmin);
  }
`;

export const RoadmapTableHeader = styled.div`
  font-size: calc(18px + 1vmin);
  font-family: 'Prompt', sans-serif;
  font-weight: 100; 
  color: #cbfb45;
  padding-top: 20px;
  @media (max-width: 768px) {
    font-size: calc(12px + 1vmin);
  }
`;

export const RoadmapTableLeftHeader = styled.div`
  font-size: calc(18px + 1vmin);
  font-family: 'Prompt', sans-serif;
  font-weight: 100; 
  color: #cbfb45;
  text-align: left;
  @media (max-width: 768px) {
    font-size: calc(12px + 1vmin);
  }
`;

export const RoadmapDot = styled.div`
  height: 90px;
  width: 90px;
  line-height: 90px;
  background-color: #cbfb45;
  border-radius: 50%;
  display: inline-block;
  font-size: calc(18px + 1vmin);
  font-family: 'Prompt', sans-serif;
  font-weight: 200; 
  color: #000;
  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
`;

export const Roadmap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 8vh 0px 6vh 0px;
  z-index:0;
`;

export const RoadmapText = styled.div`
  padding-top: 20px;
  font-size: calc(6px + 1vmin);
  line-height: calc(3px + 2vmin);
  font-family: 'Andale', sans-serif;
  font-weight: 100;
  text-align: center;
  width: 25%;
  @media (max-width: 1400px) {
    font-size: calc(10px + 1vmin);
    line-height: calc(6px + 2vmin);
  }
  @media (max-width: 900px) {
    font-size: calc(10px + 1vmin);
    line-height: calc(6px + 2vmin);
    padding-top: 10px;
    line-height: calc(6px + 2vmin);
    width: 300px;
  }
`

export const RoadmapLine = styled.div`
   background: linear-gradient(#cbfb45, #cbfb45) no-repeat center/3px 100%;
   min-width: 10px;
`;

export const SectionFour = styled.div`
  background: transparent;
  background-image: url(${backgroundImgTwo});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin:0px 0px 0px 0px;
  padding:0px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: white;
  z-index: 1;
  width:100%;
`;


export const SectionTwoContent = styled.div`
  width: 40%;
  height: 100%;
  padding: 20vmin 0vw 0px 2vw;
  @media (max-width: 768px) {
    padding: 30vmin 0vw 0px 0vw;
  }
`;

export const SectionTwoContentTwo = styled.div`
  padding: 20vw 10vw 0px 0vw;
  margin:0px 0px 30vmin 0px;
  color: black;
  width: 70%;
  font-size: calc(3px + 2vmin);
  font-family: 'Andale', sans-serif;
`;

export const SectionTwoContentTwoText = styled.p`
  font-weight: 500;
  letter-spacing: 1px;
  @media (max-width: 1400px) {
    font-size: calc(10px + 2vmin);
  }
  @media (max-width: 900px) {
    font-size: calc(6px + 2vmin);
  }
`;

export const SectionFourContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  justify-content: center;
  padding: 20vw 0vw 0px 0vw;
  margin:10vmin 0px 30vmin 0px;
  color: black;
  font-family: 'Andale', sans-serif;
`;

export const SectionFourContentText = styled.p`
  font-weight: 500;
  font-size: calc(2px + 2vmin);
  padding: 0vw 0vw 30px 30vw;
  margin: 0vmin 0px 0vmin 0px;
  letter-spacing: 1px;
  text-align: left;
  width: 750px;
  align-items: left;
  @media (max-width: 1400px) {
    font-size: calc(8px + 2vmin);
    padding: 0vw 0vw 30px 20vw;
    margin: 0vmin 0px 0vmin 0px;
  }
  @media (max-width: 900px) {
    width: 250px;
    font-size: calc(6px + 2vmin);
    padding: 0vw 0vw 20px 5vw;
    margin: 0vmin 0px 0vmin 0px;
  }
`;

export const SectionFourContentTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  justify-content: left;
  padding: 0vw 0vw 0px 0vw;
  margin:0vmin 0px 0vmin 0px;
  color: black;
  font-family: 'Andale', sans-serif;
`;

export const SectionFourContentTwoText = styled.p`
  font-weight: 500;
  font-size: calc(4px + 2vmin);
  padding: 5vw 0vw 0px 0vw;
  margin: 0vmin 0px 0vmin 0px;
  letter-spacing: 1px;
  text-align: left;
  width: 100px;
  align-items: left;
  @media (max-width: 1400px) {
    padding: 5vw 0vw 0px 0vw;
  }
  @media (max-width: 900px) {
    padding: 25vw 0vw 0px 0vw;
  }
`;

export const Contact = styled.div`
  align-items: center;
  background-color: #cbfb45;
  width: 100%;
  color: black;
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
  justify-content: space-around;
  z-index:0;
  padding: 90px 0 100px 0px;
`;

export const ContactContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px 0 0 0;
`;

export const LogoImage = styled.img`
  padding: 20px 50px 0 50px;
  width: 40px;
  @media (max-width: 768px) {
    width: 30px;
    padding: 20px 20px 0 20px;
  }
`;

export const SectionSix = styled.div`
  align-items: center;
  background-color: #000;
  padding: 20px 0px 20px 0px;
  width: 100%;
  display: flex;
  flex-wrap:wrap;
  flex-direction: row;
  justify-content: space-between;
  z-index:0;
  .logo {
    padding:15px 0px 15px 5vw;
    font-family: 'DM Serif Display', style;
    font-size: calc(22px + 2vmin);
    color: white;
  }
`;

export const SectionSixContent = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
  z-index:0;
  font-family: 'Prompt', sans-serif;
  font-size: calc(4px + 1vmin);
  font-weight: 100;
  p {
    padding-left: 3vw;
    padding-right: 5vw;
  }
`;

export const SectionFive = styled.div`
  align-items: center;
  background-color: #cbfb45;
  margin:-150px 0px 0px 0px;
  padding: 250px 0px 200px 0px;
  width: 100%;
  display: flex;
  flex-wrap:wrap;
  flex-direction: row;
  justify-content: left;
  z-index:0;
  @media (max-width: 1400px) {
    padding: 250px 0px 100px 0px;
  }
  @media (max-width: 900px) {
    padding: 200px 0px 50px 0px;
  }
`;

export const SectionFiveContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 0vw 2vw 0px 12vw;
  margin: 0vmin 0px 0vmin 0px;
  color: black;
  text-align: left;
  font-size: calc(3px + 2vmin);
  font-family: 'Andale', sans-serif;
  @media (max-width: 1400px) {
    width:500px;
    font-size: calc(10px + 2vmin);
  }
  @media (max-width: 900px) {
    font-size: calc(6px + 2vmin);
    padding: 0vw 2vw 20px 2vw;
  }
`;

export const SectionFiveContentTwo = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  width:500px;
  padding: 0vw 2vw 0px 12vw;
  font-size: calc(8px + 1vmin);
  font-family: 'Andale', sans-serif;
  @media (max-width: 1400px) {
    width:300px;
    font-size: calc(2px + 2vmin);
  }
  @media (max-width: 900px) {
    font-size: calc(4px + 2vmin);
    padding: 0vw 2vw 0px 2vw;
  }
`;

export const SectionFiveContentText = styled.p`
  font-weight: 500;
  letter-spacing: 1px;
`;

export const SectionFiveContentTwoText = styled.p`
  font-weight: bold;
  padding: 0vw 0vw 30px 0vw;
  margin: 0vmin 0px 0vmin 0px;
  letter-spacing: 1px;
  @media (max-width: 1400px) {
    padding: 0vw 0vw 10px 0vw;
  }
  @media (max-width: 900px) {
    padding: 0vw 0vw 10px 0vw;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width:60vw;
  z-index:0;
`;

export const FlatHandImage = styled.img`
  width:60%;
  padding: 0px 0px 0px 4vw;
`;

export const SabetHand = styled.img`
  width:350px;
  padding: 0px 0px 0px 0vw;
  @media (max-width: 1400px) {
    width:200px;
  }
  @media (max-width: 900px) {
    width:100px;
  }
`;

export const DownArrowImage = styled.img`
  width:8%;
  padding: 55px 0px 0px 0px;
  @media (max-width: 768px) {
    width: 20%;
  }
`;

export const ActionImage = styled.img`
  padding: 1vmin 0px 0px 10px;
  width:6vmin;
  align-items: center;
`;

export const Image = styled.img`
  width: 80vmin;
  padding: 10vh 0px 10vh 0px;
  pointer-events: auto;
  @media (max-width: 768px) {
    padding: 5vh 0px 5vh 0px;
  }
`;

export const TeamImage = styled.img`
  max-width:50vw;
  padding: 5vh 0px 10vh 0px;
  pointer-events: auto;
  @media (max-width: 768px) {
    max-width:80vw;
  }
`;

export const ActionText = styled.p`
  padding-top: 35px;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: 'Prompt', sans-serif;
  font-size: calc(2px + 2vmin);
  letter-spacing: -1px;
  font-weight: 600;
  @media (max-width: 1400px) {
    font-size: calc(12px + 2vmin);
  }
  @media (max-width: 900px) {
    font-size: calc(12px + 2vmin);
  }
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: orange;
  margin-top: 10px;
`;

export const HeaderOne = styled.div`
  font-family: 'Bebas Neue', style;
  letter-spacing: 2px;
  font-size: calc(16px + 4vmin);
  padding-bottom: 30px;
`;

export const SectionFourHeaderOne = styled.div`
 font-family: 'Bebas Neue', style;
 letter-spacing: 2px;
  font-size: calc(16px + 4vmin);
  margin:0px 0px 30px 30vw;
  @media (max-width: 1400px) {
    padding: 0vw 0vw 30px 20vw;
    margin: 0vmin 0px 0vmin 0px;
  }
  @media (max-width: 900px) {
    width: 250px;
    padding: 0vw 0vw 20px 5vw;
    margin: 0vmin 0px 0vmin 0px;
  }
`;

export const SectionFiveHeaderOne = styled.div`
  font-family: 'Bebas Neue', style;
  letter-spacing: 2px;
  font-size: calc(16px + 4vmin);
  padding-bottom: 30px;
`;

export const SectionFiveHeaderTwo = styled.div`
  color: black;
  font-family: 'CentraleBold', style;
  letter-spacing: 1px;
  font-size: calc(10px + 2vmin);
  padding-bottom: 30px;
  @media (max-width: 1400px) {
    padding: 0vw 0vw 10px 0vw;
  }
  @media (max-width: 900px) {
    padding: 0vw 0vw 10px 0vw;
  }
`;

export const ContactHeaderOne = styled.div`
  font-family: 'Bebas Neue', style;
  letter-spacing: 2px;
  text-decoration: none; !important;
  font-size: calc(8px + 5vmin);
  margin:0px 0px 20px 0px;
  a:hover, a:visited, a:link, a:active
  {
    font-family: 'Bebas Neue', style;
    color: black;  
    text-decoration: none;
  }
`;

export const ButtonContainer = styled.div`
  padding: 5vh 1vw 0px 6vw;
  font-size: calc(4px + 1vmin);
  text-align: center;
  font-family: 'Prompt', sans-serif;
`;

export const ConnectButton = styled.div`
  width: 6vw;
  height: 6vw;
  line-height:6vw;
  outline: none;
  background-color: #cbfb45;
  border-radius: 6vw;
  cursor: pointer;
  color: white;
  cursor: pointer;
  font-family: 'DM Serif Display', style;
  font-size: calc(4px + 2vmin);
  color: black;
  text-align: center;
  text-decoration: none;
  z-index: 1;
  margin-bottom:10px;
  @media (max-width: 1400px) {
    width: 8vw;
    height: 8vw;
    line-height: 8vw;
    border-radius: 8vw;
    font-size: calc(2px + 2vmin);
  }
  @media (max-width: 768px) {
    width: 12vw;
    height: 12vw;
    line-height: 12vw;
    border-radius: 12vw;
    font-size: calc(2px + 2vmin);
  }

  ${props => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }
`;

export const MintButton = styled.button`
  background-color: transparent;
  background-image: url(${mintButton});
  width: 5vmax;
  height: 5vmax;
  min-height:40px;
  min-width:40px;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  margin: 90px 20px 0px 0px;
  padding: 0px 0px 0px 0px;
  z-index: 0;

  ${props => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }
`;
