import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';

const Nav = styled.nav`
* {

    box-sizing: border-box;
  }
width: 100%;
height: 150px;
display: flex;
justify-content: space-between;
font-size: calc(14px + 1vmin);
background-color: #000;
padding: 0;
.logo {
  padding:50px 0px 15px 3vw;
  font-family: 'DM Serif Display', style;
  font-size: calc(22px + 2vmin);
  color: white;
  @media (max-width: 768px) {
    padding:20px 0px 15px 2vw;
  }
}
`;
 
const Navbar = () => {
  return (
    <Nav>
      <div className="logo">
      PAPER<span style={{ color: "#cbfb45" }}>ARK</span>
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar