import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const BurgerContainer = styled.div`
  width: calc(30px + 2vmin);
  height: calc(30px + 2vmin);
  background: #cbfb45;
  border-radius: 50%;
  position: fixed;
  padding: 10px;
  top: 45px;
  right: 80px;
  z-index: 5;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: 25px;
    right: 20px;
  }
`;

const StyledBurger = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 6000;
  padding: 0;
  width: 35px;
  height: 91%;
  @media (max-width: 768px) {
    height: 1.5rem;
  }
  div {
    padding: 0;
    width: 90%;
    height: 2px;
    background-color: ${({ open }) => open ? '#cbfb45' : 'black'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      width: ${({ open }) => open ? '95%' : '60%'};
      @media (max-width: 768px) {
        width: ${({ open }) => open ? '25px' : '10px'};
      }
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
    @media (max-width: 768px) {
      width: ${({ open }) => open ? '25px' : '15px'};
      height: 1px;
    }
  }
`;

const Burger = () => {
  const [open, setOpen] = useState(false)
  
  return (
    <>
      <BurgerContainer>
         <StyledBurger open={open} onClick={() => setOpen(!open)}>
           <div />
           <div />
           <div />
         </StyledBurger>
         <RightNav open={open}/>
      </BurgerContainer>
    </>
  )
};

export default Burger;